import MenuItem from '@mui/material/MenuItem';
import RHFSelect from './RHFSelect';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

type Props = {
  disabled?: boolean;
  name: string;
  id?: string;
  optionId?: string;
  label: string;
  placeholder: string;
  options?: any;
  sx?: object;
  onClick?: any;
  onChange?: any;
  onAdd?: VoidFunction;
  defaultMessage?: string;
  value?: string;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const RHFSelectDropdown = ({
  disabled,
  onClick,
  onChange,
  name,
  id,
  optionId,
  label,
  placeholder,
  options,
  sx,
  onAdd,
  value,
  defaultMessage,
}: Props) => {
  const theme = useTheme();
  return (
    <RHFSelect
      fullWidth
      disabled={disabled}
      onChange={onChange}
      name={name}
      id={id}
      label={label}
      value={value} 
      placeholder={placeholder}
      onClick={onClick}
      SelectProps={{ native: false, sx: { textTransform: 'capitalize' }, MenuProps }}
    >
      {(options?.length &&
        options?.map((option: any, index: any) => {
          return (
            <MenuItem
              key={index}
              id={`${optionId}.${index}`}
              value={option.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
                minHeight: ITEM_HEIGHT,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {option?.name}
            </MenuItem>
          );
        })) ||
        null}
      {defaultMessage && !options?.length && (
        <Typography
          sx={{
            mx: 3,
            my: 0.5,
            borderRadius: 0.75,
            typography: 'body2',
            textTransform: 'capitalize',
          }}
        >
          {defaultMessage}
        </Typography>
      )}
    </RHFSelect>
  );
};

export default RHFSelectDropdown;
